import React from "react";
import styled from "styled-components";
import { COLOR, FONT } from "@src/global-theme";
import Work from "@components/Work";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { BREAKPOINT } from "../global-theme";

const Landing = styled.div`
  transition: all 0.1s;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 800px;
`;

const Introduction = styled.div`
  margin-top: 2rem;
  a {
    border-bottom: 1px solid black;
    padding-bottom: 2px;
    :hover {
      opacity: 0.6;
    }
  }

  ${BREAKPOINT.md`
    margin: 8rem 0;
    max-width: 800px;
  `}
`;

const Title = styled.h1`
  font: normal 36px/1.3 ${FONT.serif};

  ${BREAKPOINT.md`
    font-size: 58px;
    line-height: 1.3;
  `}
`;

const IntroGraf = styled.p`
  transition: all 0.1s;
  font: normal 18px/1.5 ${FONT.sansSerif};

  ${BREAKPOINT.md`
    font-size: 22px;
  `}
`;

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Kayla Robertson | Home" />
      <Landing>
        <Introduction>
          <Title>Hi, I'm Kayla </Title>
          <IntroGraf>
            I'm a frontend web developer with a focus on accessibility and a background in data visualization and design. I'm
            currently based in Berlin, Germany. My website is being updated, so
            in the meantime please{" "}
            <a
              href="/kayla-robertson-CV-2021.pdf"
              aria-label="open PDF CV"
              target="_blank"
            >
              view my CV
            </a>{" "}
            or{" "}
            <a
              href="mailto:kayla.robertson3@gmail.com"
              target="_blank"
            >
              email me
            </a>{" "}
            to chat or see my work.
          </IntroGraf>
        </Introduction>
      </Landing>
    </Layout>
  );
};
export default IndexPage;
